import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tabs`}</h1>
    <p>{`The Tabs component organizes multiple sections of
related content so a client can view one section at a time.`}</p>
    <ComponentPreview componentName="tabs--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Tabs Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Tabs are used to navigate between multiple sections
of related content without leaving the context of the page.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`There needs to be at least two Tabs buttons, but not more than seven.`}</li>
      <li parentName="ul">{`The text of each Tabs button cannot be on two lines so must be kept short.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Tabs must contain Tabs buttons.`}</li>
      <li parentName="ul">{`Each Tabs button must be connected to a content panel.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Clients can navigate through the Tabs with `}<inlineCode parentName="li">{`Left`}</inlineCode>{`, `}<inlineCode parentName="li">{`Right`}</inlineCode>{`, `}<inlineCode parentName="li">{`Up`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Down`}</inlineCode>{`
arrow keys.`}</li>
      <li parentName="ul">{`Clients can jump to the first Tabs button with `}<inlineCode parentName="li">{`Home`}</inlineCode>{` and to the last Tabs
button with `}<inlineCode parentName="li">{`End`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The associated content panel is activated automatically when a client
navigates to a new Tabs button.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Tab`}</inlineCode>{` key moves the client into the content associated with the Tabs
button and `}<inlineCode parentName="li">{`Shift`}</inlineCode>{`+`}<inlineCode parentName="li">{`Tab`}</inlineCode>{` moves focus back to the button.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      